/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-22",
    versionChannel: "nightly",
    buildDate: "2024-04-22T00:05:53.012Z",
    commitHash: "15c076fe0c622dc7abe9c9bbfc8c61c1ad6f7597",
};
